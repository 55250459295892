import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import { Vector3 } from 'three'

/**
 * Base
 */
// Debug
const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const particleTexture = textureLoader.load('/textures/particles/1.png')

/**
 * Particles
 */
// Particles Material
const particlesMaterial = new THREE.PointsMaterial()
particlesMaterial.size = 0.03
// particlesMaterial.sizeAttenuation = true
// particlesMaterial.color = new THREE.Color('#ff88cc')
// particlesMaterial.transparent = true
particlesMaterial.alphaMap = particleTexture
// particlesMaterial.alphaTest = 0.01
// particlesMaterial.depthTest = false
particlesMaterial.depthWrite = false
particlesMaterial.blending = THREE.AdditiveBlending
particlesMaterial.vertexColors = true


// Geometry
const particlesGeometry = new THREE.BufferGeometry()

let numLedsInStrip = 6;
let numLedsVisible = 0; // includes POV history trail
const ledStripLength = 0.5;
const spaceBetweenLEDs = ledStripLength / (numLedsInStrip - 1);
const hubRadius = .1
const povLEDs = 800;
const xOffsetPerFramePOV = .01
let xPosition = 0
const maxLedsVisible = povLEDs * numLedsInStrip
const newLedYPositions = []
for (let i = 0; i < numLedsInStrip; i++) {
    newLedYPositions[i] = hubRadius + spaceBetweenLEDs * i
}
const positionArrayLength = maxLedsVisible * 3;
const numVerticesInStrip = 3 * numLedsInStrip


const positions = new Float32Array(positionArrayLength)
const colors = new Float32Array(positionArrayLength)

const addNewLEDs = () => {
    // console.log(newLedYPositions);
    for (let i = 0; i < numLedsInStrip; i++) {
        const distanceFromCenter = newLedYPositions[i]
        let i3 = i * 3
        positions[i3] = Math.sin(xPosition) * distanceFromCenter;
        positions[i3 + 1] = Math.cos(xPosition) * distanceFromCenter;
        positions[i3 + 2] = 0;
        let newLedColor = getNewLedColor(i)
        colors[i3] = newLedColor.x
        colors[i3 + 1] = newLedColor.y
        colors[i3 + 2] = newLedColor.z
        numLedsVisible++
    }
    numLedsVisible = Math.min(numLedsVisible, maxLedsVisible)
    particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
    particlesGeometry.setAttribute('color', new THREE.BufferAttribute(colors, 3))
    // console.log(particlesGeometry);
}


/**
 * new LED color handling
 */
const redVec3 = new Vector3(1, 0, 0)
const greenVec3 = new Vector3(0, 1, 0)
const blueVec3 = new Vector3(0, 0, 1)
const yellowVec3 = new Vector3(1, 1, 0)
const magentaVec3 = new Vector3(1, 0, 1)
const cyanVec3 = new Vector3(0, 1, 1)
const colorPallette = [redVec3, magentaVec3, blueVec3, cyanVec3, greenVec3, yellowVec3];
const getNewLedColor = ledIdx => colorPallette[ledIdx];

const updateLedPositions = () => {
    let iX, iY, iZ, prevX, prevY, prevZ, prevR, prevG, prevB
    for (let i = positionArrayLength - numVerticesInStrip; i >= numVerticesInStrip; i -= numVerticesInStrip) { // each strip
        for (let j = 0; j < numLedsInStrip; j++) {
            iX = j * 3 + i;
            iY = j * 3 + i + 1;
            iZ = j * 3 + i + 2;
            // copy postions from previous leds
            prevX = particlesGeometry.attributes.position.array[iX - numVerticesInStrip]
            particlesGeometry.attributes.position.array[iX] = prevX - xOffsetPerFramePOV
            prevY = particlesGeometry.attributes.position.array[iY - numVerticesInStrip]
            particlesGeometry.attributes.position.array[iY] = prevY
            prevZ = particlesGeometry.attributes.position.array[iZ - numVerticesInStrip]
            particlesGeometry.attributes.position.array[iZ] = prevZ
            // copy colors from previous leds
            prevR = particlesGeometry.attributes.color.array[iX - numVerticesInStrip]
            particlesGeometry.attributes.color.array[iX] = prevR
            prevG = particlesGeometry.attributes.color.array[iY - numVerticesInStrip]
            particlesGeometry.attributes.color.array[iY] = prevG
            prevB = particlesGeometry.attributes.color.array[iZ - numVerticesInStrip]
            particlesGeometry.attributes.color.array[iZ] = prevB
            // ;debugger
        }
    }
    particlesGeometry.attributes.position.needsUpdate = true
    xPosition += xOffsetPerFramePOV
}

addNewLEDs()

// console.log(`positions=${positions}`);



// Points
const particles = new THREE.Points(particlesGeometry, particlesMaterial)
scene.add(particles)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

/**
 * Axes Helper
 */
const axesHelper = new THREE.AxesHelper(2)
scene.add(axesHelper)



window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = -8
camera.position.z = 2
camera.position.y = 0
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.setClearColor(0x232333, 1);



/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () => {
    const elapsedTime = clock.getElapsedTime()

    // Update particles
    updateLedPositions()

    addNewLEDs();

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()